@import "../../public/style.scss";

.Products {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex-shrink: 0;
  background: $gn-black-color;
  overflow: hidden;
  background: -moz-linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-dark-color,1) 100%);
  background: -webkit-linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-dark-color,1) 100%);
  background: linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-dark-color,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$gn-black-color,endColorstr=$gn-dark-color,GradientType=1);
  &.Selected {
    .Menu {
      position: sticky;
      margin-top: calc( 60px + 1rem);
      top : calc( 60px + 1rem);
      right:0px;
      margin-bottom: auto;
      margin-left:auto;
    }
  }
  .Docker {
    flex-shrink: 0;
    position: relative;
    z-index : 1; 
    top:0px;
    left:0px;
    height:0px;
    width: 0px;
    right:0px; 
    transition: all 0.5s linear;
    overflow: hidden;
    &.Selected {
      z-index : 2; 
      height:auto;
      width: auto;
      //overflow: auto;
      .Title{
        opacity: 1;
        transform: scale(1) ;
        filter : blur(0px);
      }
      .Wrapper, .Mask { 
        opacity: 1;
        transform: scale(1);
        filter : blur(0px);
      }
    }
    .Mask {
      display: none;
      flex-shrink: 0;
      //display: none;
      transition: all 0.5s linear;
      position: absolute;
      top: 0px;
      left:0px;
      width:100%;
      height : 100vh;
      z-index : 2;
      transform: scale(0);
      filter : blur(1000px);
      background: -moz-linear-gradient(0deg, rgba($gn-black-color,1) 0%, rgba($gn-black-color,0.8) 50%, rgba($gn-black-color,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba($gn-black-color,1) 0%, rgba($gn-black-color,0.8) 50%, rgba($gn-black-color,0) 100%);
      background: linear-gradient(0deg, rgba($gn-black-color,1) 0%, rgba($gn-black-color,0.8) 50%, rgba($gn-black-color,0) 100%);
    }
    //background: radial-gradient(rgba($gn-black-color,0) 0%, rgba($gn-dark-color,100) 100%);
    .Title {
      background: -moz-linear-gradient(180deg, rgba($gn-black-color,0.9) 0%, rgba($gn-black-color,0.8) 90%, rgba($gn-black-color,0) 100%);
      background: -webkit-linear-gradient(180deg, rgba($gn-black-color,0.9) 0%, rgba($gn-black-color,0.8) 90%, rgba($gn-black-color,0) 100%);
      background: linear-gradient(180deg, rgba($gn-black-color,0.9) 0%, rgba($gn-black-color,0.8) 90%, rgba($gn-black-color,0) 100%);
      flex-shrink: 0;
      transition: all 0.5s linear;
      display: flex;
      flex-direction: column;
      position: absolute;
      //top:calc( 60px + 1rem );
      top:0px;
      width : 100%;
      height: auto;
      left:0rem;
      z-index :5;
      opacity: 0;
      //transform: translateX(-500px) ;
      transform: scale(5);
      filter : blur(500px);
      cursor: default;
      .Header {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: 70px;
        margin-left: 1rem;
        width : calc( 100% - 2rem - 60px);
        .Fa {
          color : $gn-white-color;
          width : 25px;
          height: 25px;
          margin: auto 0rem;
          margin-right: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .Text {
          margin: auto 0.5rem;
          color : $gn-white-color;
          font-size: 30px;
        }
        .Play {
          transition: all 0.25s ease-in-out;
          color : $gn-green-color;
          opacity: 0.5;
          width : 25px;
          height: 25px;
          margin: auto 0rem;
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
            opacity: 1;
          }
        }
      }
      .Sub-Title {
        flex-shrink: 0;
        transition: all 0.5s linear;
        display: flex;
        flex-direction: row;
        //position: absolute;
        //top:calc( 150px );
        max-width : calc( 100% - 3rem - 100px);
        //left:1rem;
        z-index :5;
        //opacity: 0;
        font-size: 15px;
        padding-top: 0px;
        margin-left : 3rem;
        padding:1rem;
        margin-bottom: 2rem;
        //border-top-right-radius: 0.25rem;
        //border-top-right-radius: 0.25rem;
        color : rgba( $gn-white-color , 0.75);
        //transform: translateX(-500px) ;
        //transform: scale(5) ; 
        //filter : blur(500px);
      }
    }

    


    .Wrapper {
      opacity: 0;
      transform: scale(0);
      filter : blur(1000px);
      transition: all 0.5s linear;
      position: relative;
      z-index :0;
      top:0px;
      left:0px;
      height:100vh;
      width: 100vw;
    }
  }

  
}


@media screen and (max-width: 750px) {
  .Products {
    height: auto;
    display: flex;
    flex-direction: column;
    //padding-top: 70px;
    .Docker {
      height: auto !important;
      display: flex;
      flex-direction: column;
      width: auto !important;
      border-bottom: 1px solid rgba( $gn-white-color , 0.1);
      .Title {
        margin-bottom: 1rem;
        top:auto;
        transform: scale(1);
        opacity: 1;
        filter: blur(0px);
        .Text {
          margin : auto 0.25rem;
        }
      }
      .Wrapper, .Mask {
        height: auto;
        opacity: 1;
        transform: scale(1);
        filter: blur(0px);
      }
      .Wrapper, .Sub-Message, .Title {
        //position: relative;
      }
      .Sub-Message, .Title {
        
      }
      .Wrapper {
        overflow: hidden;
      }
      //&.Selected {
        .Sub-Message {
          background-color: transparent !important;
          box-shadow: none !important;
          top:auto;
          backdrop-filter: none !important;
        }
      //}
    }
    .Menu {
      position: fixed;
      display: none;
    }
    &.Selected {
      .Menu {
        position: fixed;
      }
    }
  }
}