@import "./public/style.scss";

html, body {
  height: 100%;
}

body {
  background: $gn-white-color;
  overflow: hidden; // no scrollbars.. 
  font-family: $gn-font !important;
  font-weight: lighter;
}
.App {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  overflow: auto;
  scroll-behavior: smooth;


  .Menu {
    position: fixed;
    top : calc( 60px + 1rem);
    //margin-top: calc( 60px + 1rem);
    //margin-bottom: auto;
    right:0px;
    z-index : 8;
    flex-direction: column;
    display: flex;
    background-color: rgba($gn-black-color, 0.75);
    backdrop-filter: blur( 5px );
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border: 0.5px solid rgba($gn-light-color, 0.25);
    border-right-width:0px;
    width:70px;
    .Holder {
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height:60px;
      width: 70px;
      transition:all 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      &:hover {
       
        .Fa {
          //text-transform: ;
          transform: scale( 1.2 );
          text-shadow: 0 0 10px $gn-white-color, 0 0 20px $gn-white-color, 0 0 40px $gn-white-color;
        }
        .Name {
          color : rgba($gn-white-color, 0.9);
          text-shadow: 0 0 10px $gn-white-color, 0 0 20px $gn-white-color, 0 0 40px $gn-white-color;
        }
      }
      &.Selected {
        .Fa {
          transform: scale( 1.5 );
          color : rgba($gn-frame-color, 1);
          text-shadow: 0 0 10px $gn-frame-color, 0 0 20px $gn-frame-color 0 0 40px $gn-frame-color;
        }
        .Name {
          color : rgba($gn-frame-color, 0.9);
          text-shadow: 0 0 10px $gn-frame-color, 0 0 20px $gn-frame-color, 0 0 40px $gn-frame-color;
        }
      }
      .Fa {
        width: 15px;
        height: 15px;
        margin: auto;
        margin-bottom: 0.25rem;
        color : $gn-white-color;
        transition:all 0.25s ease-in-out;
      }
      .Name {
        color : rgba($gn-white-color, 0.5);
        font-size: x-small;
        margin: 0.5rem auto;
        margin-top: 0rem;
        transition:all 0.25s ease-in-out;
        word-wrap: break-word;
      }
      .background {
        clip-path: ellipse(  );
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .App {
    .Menu {
      //display: none;
    }
  }
}