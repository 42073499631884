@import "../../public/style.scss";

.Aboutus {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  //padding: 2rem 4rem;
  padding-top:80px;
  //background-color:rgba( $gn-white-color , 0.9);
  //background-size: 128px 128px;
  //backdrop-filter: blur(5px);
  background: $gn-black-color;
  background: -moz-linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-frame-color,1) 100%);
  background: -webkit-linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-frame-color,1) 100%);
  background: linear-gradient(135deg, rgba($gn-black-color,0.9) 0%, rgba($gn-frame-color,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$gn-black-color,endColorstr=$gn-frame-color,GradientType=1);

  min-height : calc( 100vh - 0rem );
  display: flex;
  flex-direction: column;
  z-index: 5;
  flex-shrink: 0;
  transition: all 0.25s ease-in-out;
  &.selected {
    .Handshake {
      opacity: 1;
      //width:100%;
      .G-Holder {
        .Hands {
          //width:200px;
          .left_boy {
            opacity: 1;
            transform : translateX(0px);
          }
        }
      }
    }
  }

  .Bg {
    display :none;
    position: absolute;
    z-index: -4;
    top:0px;
    left:0px;
    width:100%;
    height: 100%;
    //right: 0px;
    //bottom: 0px;
    //display: flex;
    flex-direction: column;
    justify-content: end;
    background: $gn-white-color;
    transition: all 0.25s ease-in-out;
    //filter: blur(5px);
    //opacity: 0.5;
    //background: radial-gradient($gn-white-color, $gn-dark-color);
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      height:100%;
      right:0px;
      background-color: white;
    }
  

  }

  .Title {
    font-size: 300%;
    text-transform: capitalize;
    margin: 1rem auto;
    position: relative;
    z-index :5;
    color : $gn-white-color;
  }
  .Handshake {
    position: absolute;
    z-index: -1;
    top:0px;
    left:0px;
    width:100%;
    //right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    transition: all 0.25s ease-in-out;
    .G-Holder {
      height:calc( 303px + 1rem);
      width:100%;
      display: flex;
      flex-direction: row;  
      .Hands {
        margin-left: auto;
        height:100%;
        //width:200px;
        transform : translateX( calc(100% - 200px) );
        width: calc( 100% + 0px);
        display: flex;
        flex-direction: row;
        transition: all 0.25s ease-in-out;
        overflow: initial;
        .client_fil9 {fill:black;fill-rule:nonzero}
        .client_fil15 {fill:#100743;fill-rule:nonzero}
        .client_fil0 {fill:#160A5B;fill-rule:nonzero}
        .client_fil3 {fill:#25136B;fill-rule:nonzero}
        .client_fil6 {fill:#300901;fill-rule:nonzero}
        .client_fil7 {fill:#433A9B;fill-rule:nonzero}
        .client_fil8 {fill:$gn-badge-color;fill-rule:nonzero}
        .client_fil13 {fill:#453C9E;fill-rule:nonzero}
        .client_fil16 {fill:#473A7A;fill-rule:nonzero}
        .client_fil14 {fill:$gn-dark-color;fill-rule:nonzero}
        .client_fil5 {fill:#5E1C17;fill-rule:nonzero}
        .client_fil10 {fill:#6760AE;fill-rule:nonzero}
        .client_fil11 {fill:#8EAFC1;fill-rule:nonzero}
        .client_fil2 {fill:#D8D8D8;fill-rule:nonzero}
        .client_fil4 {fill:#DDAC9E;fill-rule:nonzero}
        .client_fil1 {fill:#E8C8BA;fill-rule:nonzero}
        .client_fil12 {fill:white;fill-rule:nonzero}
        .Frame {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
      .left_boy {
        opacity: 1;
        transform : translateX(-2000px);
        transition: all 1s ease-in;
      }
        
    } 
  }

  .Messages{
    position: relative;
    z-index: -2;
    width : 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 0rem 2rem;
    .Msg-Box {
      position: relative;
      z-index: -2;
      width : fit-content;
      height: auto;
      //margin-bottom: 300px;
      margin : 0rem 1rem;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      padding : 2rem 2rem;
      //padding-bottom: 10%;
      //margin-bottom:320px;
      font-weight: light;
      max-width: 751px;
      animation: float 6s ease-in-out infinite;
      //border: 2px solid rgba($gn-white-color, 0.5);
      //border-radius : 50px;
      .Frame {
        //display: none;
        position: absolute;
        top:0px;
        left:0px;
        bottom:0px;
        right:0px;
        background-color: rgba($gn-white-color, 0.5);
        clip-path: polygon(0% 83%, 0% 0%, 100% 0%, 100% 81%, 99% 81%, 99% 1%, 1% 1%, 1% 82%, 62% 85%, 88% 97%, 100% 80%, 90% 100%, 63% 86%);
        margin-bottom: -20%;
      }
      .Msg {
        font-size: 100%;
        color : $gn-white-color;
        //font-weight: bold;
        //margin: 0.5rem 0rem;
        //margin-bottom: 1rem;
      }
      &:first-child {
        //margin-right: 50px;
        //margin-bottom : 30px
      }
      &:nth-child(2) {
        //margin-right: 150px;
        //margin-bottom : 25px
        margin-top:4rem;
      }
      &:nth-child(3) {
        //margin-right: 220px;
        //margin-bottom : 28px
        margin-top:8rem;
      }
    }
    @keyframes float {
      0% {
        //box-shadow: 0 5px 15px 0px rgba($gn-black-color,0.6);
        transform: translate(0px, 0px);
      }
      50% {
        //box-shadow: 0 25px 15px 0px rgba($gn-black-color,0.2);
        transform: translate(-20px, 20px);
      }
      100% {
        //box-shadow: 0 5px 15px 0px rgba($gn-black-color,0.6);
        transform: translate(0px, 0px);
      }
    }
  }

  .Truck {
      margin: 0 auto;
      position: absolute;
      display: block;
      width: 100%;
      left: 0px;
      height: 400px;
      bottom : 0px;
      overflow: hidden;
      border-bottom: 3px solid #fff;
      color: #fff;
      z-index :0;
      .mountain {
        position: absolute;
        right: -900px;
        bottom: -20px;
        width: 2px;
        height: 2px;
        box-shadow: 
          0 0 0 50px $gn-dark-color,
          60px 50px 0 70px $gn-dark-color,
          90px 90px 0 50px $gn-dark-color,
          250px 250px 0 50px $gn-dark-color,
          290px 320px 0 50px $gn-dark-color,
          320px 400px 0 50px $gn-dark-color
          ;
        transform: rotate(130deg);
        animation: mtn 20s linear infinite;
      }
      .hill {
        position: absolute;
        right: -900px;
        bottom: -50px;
        width: 400px;
        border-radius: 50%;
        height: 20px;
        box-shadow: 
          0 0 0 50px $gn-dark-color,
          -20px 0 0 20px $gn-dark-color,
          -90px 0 0 50px $gn-dark-color,
          250px 0 0 50px $gn-dark-color,
          290px 0 0 50px $gn-dark-color,
          620px 0 0 50px $gn-dark-color;
        animation: hill 4s 2s linear infinite;
      }
      .tree, .tree:nth-child(2), .tree:nth-child(3) {
        position: absolute;
        height: 100px; 
        width: 35px;
        bottom: 0;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/tree.svg) no-repeat;
      }
      .rock {
        margin-top: -17%;
        height: 2%; 
        width: 2%;
        bottom: -2px;
        border-radius: 20px;
        position: absolute;
        background: #ddd;
      }
      .truck, .wheels {
        transition: all ease;
        width: 85px;
        margin-right: -60px;
        bottom: 0px;
        right: 50%;
        position: absolute;
        background: #eee;
      }
      .truck {
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/truck.svg) no-repeat;
        background-size: contain;
        filter: hue-rotate(45deg);
        height: 60px;
      }
      .truck:before {
        content: " ";
        position: absolute;
        width: 25px;
        box-shadow:
          -30px 28px 0 1.5px #fff,
          -35px 18px 0 1.5px #fff;
      }
      .wheels {
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/wheels.svg) no-repeat;
        height: 15px;
        margin-bottom: 0;
      }    
      .tree  { animation: tree 3s 0.000s linear infinite; }
      .tree:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
      .tree:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
      .rock  { animation: rock 4s   -0.530s linear infinite; }
      .truck  { animation: truck 4s   0.080s ease infinite; }
      .wheels  { animation: truck 4s   0.001s ease infinite; }
      .truck:before { animation: wind 1.5s   0.000s ease infinite; }
      
      
      @keyframes tree {
        0%   { transform: translate(1350px); }
        50% {}
        100% { transform: translate(-50px); }
      }
      @keyframes tree2 {
        0%   { transform: translate(650px); }
        50% {}
        100% { transform: translate(-50px); }
      }
      @keyframes tree3 {
        0%   { transform: translate(2750px); }
        50% {}
        100% { transform: translate(-50px); }
      }
      
      @keyframes rock {
        0%   { right: -200px; }
        100% { right: 2000px; }
      }
      @keyframes truck {
        0%   { transform: translate(0px); }
        16%   { transform: translateY(0px); }
        17%   { transform: translateY(-6px); }
        18%   { transform: translateY(0px); }
        19%   { transform: translateY(-1px); }
        20%   { transform: translateY(0px); }
        25%   { transform: translateX(10px); }
        40%   { transform: translateX(20px); }
        40%   { transform: translateX(15px); }
        60%   { transform: translateX(20px); }
        100%   {  transform: translate(0px);}
      }
      @keyframes wind {
        0%   {  }
        50%   { transform: translateY(3px) }
        100%   { }
      }
      @keyframes mtn {
        100% {
          transform: translateX(-2000px) rotate(130deg);
        }
      }
      @keyframes hill {
        100% {
          transform: translateX(-2000px);
        }
      }
  }
}

@media screen and (max-width: 750px) {
  .Aboutus {
    padding: 2rem 1rem;
    padding-top:80px;
    .Messages {
      padding: 0rem 1rem;
      padding-bottom: 50px;
      flex-direction: column;
      .Msg-Box {
        margin-top: 2rem !important;
        .Frame {
          margin-bottom: -3rem;
        }
        &:last-child {
          margin-bottom: 8rem !important;
        }
      }
    }
  }
}