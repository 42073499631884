@import "../../../public/style.scss";

.Finit {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba( $gn-badge-color , 0.75);
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex-shrink: 0;
  &:hover {
    //background-color: rgba( $gn-frame-color , 0.9);
  }
  .Bg {
    position: absolute;
    z-index : -1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    opacity: 0.5;
    overflow: hidden;
    .Img {
      filter: blur(0px);
      position: absolute;
      height:125%;
      opacity: 0.25;
    }
  }

  .Container {
    position: relative;
    z-index :1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    .Holder {
      position: relative;
      top:0px;
      left:0px;
      width :100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      .Box {
        position: relative;
        perspective: 50vw;
        perspective-origin: center center;
        width : calc( 100% - 0rem);
        margin: auto 0%;
        margin-top: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        --m1 : flex;
        --m2 : none;
        &:hover {
          --m1 : none;
          --m2 : flex;
          .i-Container {
            //.Img {
              &.Left {
                transform:rotateY(90deg) translateX(-25vw);
              }
              &.Right {
                transform:rotateY(0deg) translateX(-25vw);
              }
            //}
          }
          .Message {
            transform: translateX(-25vw);
          }
        }
        .i-Container {
          width:25vw;
          height: 50vh;
          overflow: hidden;
          flex-shrink: 0;
          transition: all 2s ease-in-out;
          .Img {
            //height: 50vh;
            //width :25vw;
            height : 100%;
            //width:100%;
            transform-style: preserve-3d;
            box-shadow: 0px 0px 50px rgba($gn-black-color, 0.5);
            border : 3px dashed rgba( $gn-badge-color , 0.5);
            transition: all 2s ease-in-out;
            //float: left;
            
          }
          &.Left {
            transform: rotateY( 0deg ) translateX( 0vw );
            transform-origin: center right;
            //animation : flipL 7s infinite linear;
          }
          &.Right {
            transform: rotateY( -90deg ) translateX( 0vw );
            transform-origin: left center;
            //animation : flipR 7s infinite linear;
          }
        }
        .Message {
          //float: left;
            flex-shrink: 0;
            background-color: rgba($gn-white-color, 0.8);
            backdrop-filter: blur(5px);
            //height: 50vh;
            height: auto;
            width:75vw;
            margin:auto;
            flex-grow: 1;
            transform: translateX(0vw);
            transition: all 2s ease-in-out;
            padding:1.5rem 2rem;
            border : 3px dashed rgba( $gn-badge-color , 0.5);
            position: relative; 
            //margin-left: calc( -25% * 0.25 - 2rem  );
            //animation : converge 7s infinite linear;
            .Msg {  
              //display: flex;
              flex-direction: row;    
              position: relative;        
              .Wrapper {
                flex-basis: 33%;
                display : flex;
                flex-direction: column;
                justify-content: center;
                padding:1rem 1rem;
                height : 100%;
                width : 33%;
                cursor: default;
                .Fa {
                  width : 50px;
                  height: 50px;
                  margin: 1rem auto;
                  color : $gn-badge-color;
                }
                .Desc {
                  font-size: 15px;
                  color: rgba( $gn-black-color, 0.8 );
                  text-align: center;
                }
              }
              &.Msg1 {
                transition: all 2s ease-in-out;
                display: var(--m1);
              }
              &.Msg2 {
                transition: all 2s ease-in-out;
                display: var(--m2);
              }
            }
        }
      }
      
      @keyframes converge {
        0% { transform: translateX(0vw); }
        50% { transform: translateX( -25vw ); }
        51% { transform: translateX( -25vw ); }
        100% { transform: translateX( 0vw); }
      }
      @keyframes flipL {
        0% { transform:rotateY(0deg) translateX(0vw); }
        25% { transform:rotateY(90deg) translateX(-25vw); }
        75% { transform:rotateY(90deg) translateX(-25vw); }
        100% { transform:rotateY(0deg) translateX(0vw); }
      }
      @keyframes flipR {
        0% { transform:rotateY(-90deg) translateX(0vw); }
        50% { transform:rotateY(0deg) translateX(-25vw); }
        51% { transform:rotateY(0deg) translateX(-25vw); }
        100% { transform:rotateY(-90deg) translateX(0vw); }
      }
    }
    
  }
}


@media screen and (max-width: 750px) {
  .Finit {
    height: auto;
    .Container {
      height:fit-content;
      .Holder {
        .Box {
          .i-Container {
            display: none;
            .Img {
              
            }
          }
          .Message {
            padding-top: 275px;
            animation: none;
            width:100%;
            .Msg {
              &.Msg1, &.Msg2 {
                display: block;
              }
              flex-direction: column;
              .Wrapper {
                width:100%;
                flex-basis: 100%;

              }
            }
          }
          &:hover {
            .Message {
              transform: translateX(0vw);
            }
          }
        }
      }
    }
  }
}