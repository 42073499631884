@import "../../../public/style.scss";

.Jumi {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba( $gn-black-color , 0.9);
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: column;
  z-index: 5;
  flex-shrink: 0;
  .Bg {
    position: absolute;
    z-index : -1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: hidden;
    .Img {
      //ilter: blur(3px);
      position: absolute;
      height:125%;
      z-index : -1;
      opacity: 0.5;
      &.First {
        opacity : 0.5;
        animation: dissolve1 3s ease-in-out infinite;
      }
      &.Second {
        opacity : 0;
        animation: dissolve2 3s ease-in-out infinite;
      }
      @keyframes dissolve1 {
        0% { opacity: .5; }
        100% { opacity: 0; }
      }
      @keyframes dissolve2 {
        0% { opacity: 0; }
        100% { opacity: .5; }
      }
    }
    .Top {
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      position: absolute;
      background: -moz-linear-gradient(0deg, rgba($gn-black-color ,1) 0%, rgba($gn-black-color ,0.8) 20%, rgba($gn-black-color ,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba($gn-black-color ,1) 0%, rgba($gn-black-color ,0.8) 20%, rgba($gn-black-color ,0) 100%);
      background: linear-gradient(0deg, rgba($gn-black-color ,1) 0%, rgba($gn-black-color ,0.8) 20%, rgba($gn-black-color ,0) 100%);
    }
  }
  .RFrame {
    border-bottom: 1px solid rgba(var(--gn-black-color),.05);
    flex-shrink: 0;
    width:95%;
    height: 100px;
    margin: auto ;
    margin-bottom: 0rem;
    position: relative;
    flex-shrink: 0;
  }

  .Messages {
    //width : calc(100% - 300px);
    height : auto;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction:row;
    width: auto;
    position: relative;
    z-index : 5;
    background-color: rgba($gn-black-color ,0.5);
    backdrop-filter: blur(5px);
    padding:1rem 2rem;
    border-radius: 0.5rem;
    width:95%;
    .MyHolder {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width:25%;
      flex-basis: 25%;
      margin-bottom: 0rem;
      transition: all 0.25s ease-in-out;
      cursor: default;
      &:hover {
        transform : scale(1.1);
      }
      &.Selected {
        transform: scale(1.1);
        .Headline {
          .Fa {
            color : $gn-red-color;
          }
          .HLine {
            color : $gn-red-color;
          }
        }
        .Desc{ 
          color : $gn-red-color;
        }
      }
      .Headline {
        display: flex;
        flex-direction: flex;
        margin-bottom: 0.25rem;
        .Fa {
          width :20px;
          height: 20px;
          color : $gn-white-color;
          margin: auto 0.5rem;
        }
        .HLine {
          margin: auto;
          margin-left: 0rem;
          font-size: 18px;
          color : $gn-white-color;
          text-transform: uppercase;
        }
      }
      .Desc{
        margin-left: calc( 25px + 1rem );
        font-size: 15px;
        color : rgba( $gn-white-color, 0.75 );
        margin-bottom: 0rem;
      }
    }
  }
  
}


@media screen and (max-width: 750px) {
  .Jumi {
    height: auto;
    display: flex;
    flex-direction: column;
    .RFrame {
      display: none;
      margin-top: 275px;
      margin-bottom: 2rem;
    }
    .Messages {
      margin-top: 275px;
      flex-direction: column;
      margin-bottom: 2rem;
      .MyHolder {
        width:100%;
        flex-basis: 100%;
        margin: 1rem auto;
      }
    }
  }
}