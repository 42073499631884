/*FONT FAMILY*/
//@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:regular,bold&subset=Latin');
//@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400&display=swap');
//@import url('https://fonts.googleapis.com/earlyaccess/notosanssc.css');

//$gn-font : ProximaNova,Arial,sans-serif;
/*$gn-font : 'Barlow', sans-serif;*/
$gn-font : 'Ubuntu', 'Noto Sans TC', 'Noto Sans SC';
/*$gn-font : Trebuchet MS,roboto,ubuntu,sans-serif;*/
//$gn-font : 12px/1.5 Century Gothic,arial,tahoma,'Hiragino Sans GB','\5b8b\4f53',sans-serif;
/*$gn-font : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;*/
//$gn-font : "Microsoft YaHei" ,'Heiti TC', 'Heiti SC', Tahoma, Verdana, STHeiTi ; 

/*FRAME COLORS*/

//gn-logo :polygon(37% 10%, 1% 49%, 37% 89%, 59% 65%, 49% 54%, 37% 68%, 19% 49%, 37% 30%, 90% 88%, 99% 78%, 73% 49%, 99% 20%, 90% 10%, 63% 39%);
$gn-logo : polygon(10% 10%, 39% 36%, 10% 63%, 49% 99%, 89% 63%, 65% 41%, 55% 51%, 68% 63%, 49% 80%, 30% 63%, 89% 10%, 78% 1%, 49% 27%, 20% 0%);

$gn-bgcolor: #faf9f6;
$gn-black-color : #000000;
$gn-dark-color: #354149;
$gn-grey-color:#bababa;
$gn-white-color: #FFFFFF;
$gn-light-color : #F9F9F9;
$gn-green-color : #39C27F;
$gn-red-color :  #CE2929;
$gn-darker-color : #222222;
$gn-frame-color: #3F95F7;
$gn-frame-color-2 : #344055;
$gn-frame-color-3 : #CFB3CD;
$gn-frame-color-4 : #DFC2F2;
$gn-accent-color : #C300EA;
$gn-accent-color-2 : #B7D3F2;
$gn-accent-color-3 : #84AFE6;
$gn-badge-color : #E59F29;
$gn-white-color: #FFFFFF;



:export {
    gnBlackColor: $gn-black-color;
    gnGreyColor: $gn-grey-color;
    gnLightColor: $gn-light-color;
    gnDarkColor: $gn-dark-color;
    gnWhiteColor : $gn-white-color;

    gnFrameColor: $gn-frame-color;
    gnFrameColor2: $gn-frame-color-2;
    gnFrameColor3: $gn-frame-color-3;
    gnFrameColor4: $gn-frame-color-4;

    gnGreenColor: $gn-green-color;
    gnRedColor: $gn-red-color;

    gnAccentColor: $gn-accent-color;
    gnAccentColor2: $gn-accent-color-2;
    gnAccentColor3: $gn-accent-color-3;
    gnBadgeColor: $gn-badge-color;
    gnFont : $gn-font ;
}
