@import "../../../public/style.scss";

.Gp {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba( $gn-dark-color , 0.9);
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: 5;
  flex-shrink: 0;
  .Bg {
    position: absolute;
    z-index : -1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: hidden;
    filter: brightness(0.3);
    .Img {
      filter: blur(0px);
      position: absolute;
      height:125%;
      opacity: 0.25;
    }
  }

  .Container {
    position: relative;
    z-index :1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    .Box {
      position: absolute;
      animation: float 2s infinite linear;
      //border: 2px solid rgba( $gn-white-color , 0.25 );
      //box-shadow: 0px 0px 5px rgba( $gn-white-color , 0.25 );
      border-radius: 0.5rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .Img {
        flex-grow: 1;
        height: 100%;
        //width : 100%;
        //max-height: 300px;
      }
      &.b1 {
        top : 275px;
        left: 1.5rem;
        width: 30%;
        max-width: 300px;
        //transform: rotate(1.5deg);
      }
      &.b2 {
        left: 2.5rem;
        bottom: 90px;
        width: 35%;
        max-width: 250px;
        //transform: rotate(-1.5deg);
      }
      &.b3 {
        top: 270px;
        right:4rem;
        width: 55%;
        max-width: 600px;
        //transform: rotate(-2deg);
      }
      &.b4 {
        bottom: 60px;
        left : 45%;
        width: 30%;
        max-width: 250px;
        //transform: rotate(1deg);
      }
      .Msg {
        font-size: x-small;
        color :rgba( $gn-white-color , 0.8 );
        background-color: rgba( $gn-black-color , 0.9);
        padding : 0.25rem 1rem;
        text-align: center;
      }
    }
    @keyframes float {
      0% {
        transform: translate(0px, 0px) rotate(0deg);
      }
      33% {
        transform: translate(-2.5px, 2.5px) rotate(1deg);
      }
      66% {
        transform: translate(2.5px, 2.5px) rotate(-1deg);
      }
      100% {
        transform: translate(0px, 0px) rotate(0deg);
      }
    }
  }

}

@media screen and (max-width: 750px) {
  .Gp {
    height: auto;
    .Container {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: calc( 60px + 4rem );
      height: auto;
      .Box {
        position: relative;
        width: calc( 80% - 0rem) !important;
        margin: 1rem auto;
        top :auto !important;
        left:auto !important;
        bottom:auto !important;
        right: auto !important;
        .Img {
          width :100%;
          height: auto;
        }
        
      }
    }
  }
}