@import "../../public/style.scss";

.Partners {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  //background-color: rgba( $gn-white-color , 0.9);
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex-shrink: 0;
  background: -moz-linear-gradient(135deg, rgba($gn-black-color ,0.5) 0%, rgba($gn-white-color ,0.4) 30%, rgba($gn-white-color ,1) 100%);
  background: -webkit-linear-gradient(135deg, rgba($gn-black-color ,0.5) 0%, rgba($gn-white-color ,0.4) 30%, rgba($gn-white-color ,1) 100%);
  background: linear-gradient(135deg, rgba($gn-black-color ,0.5) 0%, rgba($gn-white-color ,0.4) 30%, rgba($gn-white-color ,1) 100%);
}

@media screen and (max-width: 750px) {
  .Partners {
    //height: auto;
  }
}