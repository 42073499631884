@import "../../../public/style.scss";

.Demi {
  position: relative;
  top: 0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba( #B51700 , 0.5);
  backdrop-filter: blur(5px);
  height : 100vh;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex-shrink: 0;
  .Bg {
    position: absolute;
    z-index : -1;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: hidden;
    .Img {
      filter: blur(0px) brightness(0.5);
      position: absolute;
      height:125%;
      //transition: all 0.25s ease-in-out;
      &.First {
        opacity : 1;
        animation: dissolveN 6s ease-in-out infinite;
        z-index : -1;
      }
      &.Second {
        opacity : 1;
        //animation: dissolve1 6s linear infinite;
        z-index : -2;
      }
      @keyframes dissolveN {
        0% { opacity: 1; }
        50% { opacity: 0; }
        99% { opacity: 1; }
      }
      @keyframes dissolve2 {
        0% { opacity: 0;}
        25% { opacity: 1; }
        75% { opacity: 1; }
        99% { opacity: 0; }
      }
    }
    .Top {
      top:0px;
      left:0px;
      width:100%;
      height: 100%;
      position: absolute;
      z-index: 5;
      //background-color: rgba( $gn-black-color, 0.5 )  ;
      background: -moz-linear-gradient(20deg, rgba(#B51700 ,0.5) 0%, rgba(#B51700 ,0.4) 20%, rgba(#B51700 ,0) 100%);
      background: -webkit-linear-gradient(20deg, rgba(#B51700 ,0.5) 0%, rgba(#B51700 ,0.4) 20%, rgba(#B51700 ,0) 100%);
      background: linear-gradient(20deg, rgba(#B51700 ,0.5) 0%, rgba(#B51700 ,0.4) 20%, rgba(#B51700 ,0) 100%);
      //animation: icolor 6s ease-in-out infinite;
      @keyframes icolor {
        0% {
          background: -moz-linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
          background: -webkit-linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
          background: linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
        }
        30% {
          background: -moz-linear-gradient(60deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.5) 35%, rgba(#B51700 ,0) 100%);
          background: -webkit-linear-gradient(60deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.5) 35%, rgba(#B51700 ,0) 100%);
          background: linear-gradient(60deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.5) 35%, rgba(#B51700 ,0) 100%);
        }
        70% {
          background: -moz-linear-gradient(-40deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.66) 10%, rgba(#B51700 ,0) 100%);
          background: -webkit-linear-gradient(-40deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.66) 10%, rgba(#B51700 ,0) 100%);
          background: linear-gradient(-40deg, rgba(#B51700 ,1) 0%, rgba(#B51700 ,0.66) 10%, rgba(#B51700 ,0) 100%);
        }
        100% {
          background: -moz-linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
          background: -webkit-linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
          background: linear-gradient(0deg, rgba(#B51700 ,0.25) 0%, rgba(#B51700 ,0.1) 20%, rgba(#B51700 ,0) 100%);
        }
      }
    }
  }
  .Holder {
    margin: auto;
    margin-top: auto;
    margin-left: 20%;
    margin-bottom: 2rem;
    //width : 300px;
    width : 300px;
    height: 500px;
    position: relative;
    z-index :1;
    animation: float 2s linear infinite;
    &:hover {
      .Carrier {
        &:first-child {
          animation: openL 1s linear 1;
        }
        &:last-child {
          animation: openR 1s linear 1;
        }
      }
    }
    .Carrier {
      position: absolute;
      width : 300px;
      height: auto;
      z-index : 5;
      
      &:first-child {
        transform: rotate(-10deg) scale(0.9) translateX(-200px);
        z-index : 2;
        filter: brightness(0.8);
        
      }
      &:last-child {
        transform: rotate(20deg) scale(0.75) translateX(220px);
        z-index : 1;
        filter: brightness(0.6);
        
      }
      .Card {
        box-shadow: 0px 0px 10px rgba( $gn-white-color, 0.75 );
      }
      @keyframes openL {
        0% { transform: rotate(0deg) scale(1) translateX(-0px) }
        100% { transform: rotate(-10deg) scale(0.9) translateX(-200px)}
      }
      @keyframes openR {
        0% { transform: rotate(0deg) scale(1) translateX(-0px) }
        100% { transform: rotate(20deg) scale(0.75) translateX(220px); }
      }
    }
    @keyframes float {
      0% {
        //box-shadow: 0 5px 15px 0px rgba($gn-black-color,0.6);
        transform: translate(0px, 0px);
      }
      50% {
        //box-shadow: 0 25px 15px 0px rgba($gn-black-color,0.2);
        transform: translate(-20px, 20px);
      }
      100% {
        //box-shadow: 0 5px 15px 0px rgba($gn-black-color,0.6);
        transform: translate(0px, 0px);
      }
    }
  }
  .Messages {
    //width : calc(100% - 300px);
    height : auto;
    margin-top: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
    margin-left: auto;
    margin-right: 3rem;
    width: auto;
    position: relative;
    z-index : 5;
    background-color: rgba(#B51700 ,0.5);
    backdrop-filter: blur(5px);
    padding:1rem 2rem;
    //border-radius: 0.5rem;
    .MyHolder {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      margin-bottom: 1rem;
      transition: all 0.25s ease-in-out;
      cursor: default;
      &:hover {
        transform : scale(1.1);
      }
      .Headline {
        display: flex;
        flex-direction: flex;
        margin-bottom: 0.25rem;
        .Fa {
          width :20px;
          height: 20px;
          color : $gn-white-color;
          margin: auto 0.5rem;
        }
        .HLine {
          margin: auto;
          margin-left: 0rem;
          font-size: 18px;
          color : $gn-white-color;
          text-transform: uppercase;
        }
      }
      .Desc{
        margin-left: calc( 25px + 1rem );
        font-size: 15px;
        color : rgba( $gn-white-color, 0.75 );
        margin-bottom: 1rem;
      }
    }
  }
}


@media screen and (max-width: 750px) {

  .Demi {
    flex-direction: column;
    height: auto;
    .Holder {
      margin: auto;
      margin-top: 275px;
      margin-bottom: 2rem;
    }
    .Messages {
      margin: auto;
      margin-bottom: 2rem;
      
    }
  }
}