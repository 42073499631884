@import "../../../../public/style.scss";

.Roadmap {
  background-color: rgba( $gn-white-color, 0.75);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  backdrop-filter: blur(5px);
  //box-shadow: 0px 0px 10px rgba( $gn-white-color, 0.75);
  .Top {
    display: flex;
    flex-direction: row;
    height: 40px;
    .Btn {
      height:30px;
      margin : auto 0.5rem;
      padding: 0rem 1rem;
      background-color: #B51700;
      border-color: #B51700;
      .Fa {
        color : rgba( $gn-white-color, 1.0 );
        margin-right: 0.5rem;
      }
    }
  }

  .Bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    .RTitle {
      z-index: 5;
      color : rgba( $gn-white-color, 1.0);  
      margin:auto 0px;
      font-weight: lighter;
      background-color: rgba( #B51700, 1.0); 
      transition: all 0.25s ease-in-out;
      padding : 0.25rem 0.5rem;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 1rem;
      .Fa {
        font-size: 100%;
        margin : auto 0.5rem;
      }
      .Name {
        margin : auto 0.5rem;
        font-size: 100%;
      }
      &.Not {
        background-color: rgba( $gn-grey-color, 1); 
      }
      &.Selected {
        transform: scale(1.1);
      }
      &.Last {
        background-color: rgba( $gn-dark-color, 1) !important;
        &.Selected {
          .Fa {
            color: rgba( $gn-green-color, 1) !important; 
          }
        }
      }
    }
    .Road {
      z-index: 1;
      position: absolute;
      width:100%;
      left:0%;
      border-bottom: 4px solid rgba( $gn-black-color, 0.25);
      top: calc( 50% - 2px);
    }
    .Road-Cover {
      z-index: 2;
      position: absolute;
      transition: all 0.25s ease-in-out;
      //width:100%;
      left:0%;
      border-bottom: 4px solid rgba( #B51700, 1.0);
      top: calc( 50% - 2px);
    }
  }

}

@media screen and (max-width: 750px)  {
  .Roadmap {
    .Bottom {
      .RTitle{
        flex-direction: column;
        justify-content: center;
        .Fa, .Name { 
          font-size: 70%;
          margin: auto;
        }
      }
    }
  }
}