@import "../../public/style.scss";

.Cover {
  position:  relative;
  top:0px;
  left:0px;
  width:100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index : 0;
  flex-shrink: 0;
  overflow: hidden;
  //background: $gn-white-color;
  .Bg {	
    position:absolute;
    background: cover;
    //background: linear-gradient(145deg, #192839 50%, #1ab4e2 115%);
    //background-color: red;
    //background: linear-gradient(145deg, rgba($gn-dark-color, 1) 40%, $gn-white-color 100%);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -3;
    background-color: rgba($gn-black-color,0.6);
    //background: rgba($gn-black-color , 0.5);
    //background: -moz-radial-gradient(circle, rgba($gn-black-color ,0.25) 0%, rgba($gn-black-color ,1) 100%);
    //background: -webkit-radial-gradient(circle, rgba($gn-black-color ,0.25) 0%, rgba($gn-black-color ,1) 100%);
    //background: radial-gradient(circle, rgba($gn-black-color ,0.25) 0%, rgba($gn-black-color ,1) 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000000",endColorstr="#000000",GradientType=1);
    //backdrop-filter: blur(5px);
    //animation:bg 5s linear infinite;
  }

  .Msg-Holder {
    display: none;
    position: absolute;
    top : 0rem;
    left: 0rem;
    width : calc( 100% - 0rem);
    height : 100vh;
    z-index : 1;
    margin:0px;
    //text-align:center;
    //padding-top:43vh;
    color:#fff;
    font-size:18px;
    font-weight:100;
    //overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-items: end;
    transition: all 0.25s ease-in-out;
    background-image: radial-gradient(rgba( $gn-black-color, 0.25) 1px, transparent 1px);
    background-size: 5px 5px;
    background-color: rgba(71, 211, 255, 0.01);
    //animation:bg 5s linear infinite;
    .dropping-texts {
      //display: flex;
      //flex-direction: row;
      text-align: center;
      width: calc(100% - 0rem);
      //text-align: left;
      //height: 36px;
      height:auto;
      vertical-align: -2px;
      margin: auto;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .Msg {
        //margin: auto;
        font-size:18px;
        opacity:1;
        margin:1rem 0rem;
        margin-right: 0px;
        //margin-left:-30px;
        text-align: center;
        position: relative;
        //font-weight:300;   
        width: auto;
        //box-shadow: 0px 45px 25px -20px rgba($gn-white-color,0.1);
        transition: all 0.25s ease-in-out;
        white-space: nowrap;
        animation: roll2 8s linear 1 5s;
        text-transform: uppercase;
        color : $gn-white-color;
        cursor: pointer;
        text-shadow: 0 0 10px $gn-white-color, 0 0 20px $gn-white-color, 0 0 40px $gn-white-color;
        display: flex;
        flex-direction: row;
        &:hover {
          text-shadow: 0 0 10px $gn-frame-color, 0 0 20px $gn-frame-color, 0 0 40px $gn-frame-color;
          color : $gn-frame-color;
          transform: scale(1.1);
        }
        &:nth-child(1), &:nth-child(2) {
          &:after {
            content : " ";
            display: block;
            width:5px;
            height:5px;
            margin: auto 1rem;
            border-radius: 100%;
            background-color: rgba($gn-white-color , 0.9);
            box-shadow: 0px 0px 5px rgba($gn-white-color , 0.9);
          }
        }
        &:nth-child(1) {
          animation: roll2 5s linear 1 0s;
        }
        &:nth-child(2) {
          animation: roll2 9s linear 1 0s;
        }
        &:nth-child(3) {
          animation: roll2 7s linear 1 0s;
        }    

        @keyframes roll2 {
          0% {
            position: absolute;
            margin: auto;
            font-size: 90vh;
            opacity: 0;
          }
          5% {
            position: absolute;
            opacity: 1;
          }
          99% {
            position: absolute;
          }
          100% {
            position: absolute;
            opacity: 1;
          }
        }
        
        
      }
      
    }
    @keyframes bg {
      0% {background: #ff007511;}
      3% {background: #0094ff11;}
      20% {background: #0094ff11;}
      23% {background: #b200ff11;}
      40% {background: #b200ff11;}
      43% {background: #8BC34A11;}
      60% {background: #8BC34A11;}
      63% {background: #F4433611;}
      80% {background: #F4433611;}
      83% {background: #F4433611;}
      100% {background: #F4433611;}
    }
    
    
  }
  
  .Video {
    //opacity: 0.75;
    //display: none !important;
    position: absolute;
    top:0px;
    bottom:0px;
    width:100% !important;
    height:100% !important;
    z-index: -4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    //filter: blur(2px);
    video {
      margin:auto;
      width : auto !important;
      height:100% !important;
    }
    //animation: move 19s ease-in infinite 8s;
  }

  .Title {
    //display: none;
    display: flex;
    flex-direction: row;
    position: relative;
    animation: appear 6s linear 1 0s;
    z-index: 4;
    cursor: default;
    .Logo {
      position: relative;
      width : 100px;
      height: 100px;
      margin: auto 0px;
      margin-left: auto;
      //margin-right: 0.25rem;
      .Clip {
        position: relative;
        height:100%;
        width:100%;
        //background-color: $gn-white-color;
        //background: radial-gradient(circle, rgba($gn-black-color ,0) 0%, rgba($gn-black-color ,0.8) 100%);
        background: linear-gradient(90deg, 
          rgba($gn-frame-color ,1) 0%, 
          rgba($gn-white-color ,0.25) 50%,
          rgba($gn-white-color ,0.25) 51%,
          rgba($gn-frame-color ,1) 100%, 
          ) ;
        animation: shine 3s infinite linear;
        animation-fill-mode: forwards;
        //clip-path: polygon(37% 10%, 1% 49%, 37% 89%, 59% 65%, 49% 54%, 37% 68%, 19% 49%, 37% 30%, 90% 88%, 99% 78%, 73% 49%, 99% 20%, 90% 10%, 63% 39%);
        clip-path: $gn-logo;
        &.Border {
          position: absolute;
          z-index : -1;
          transform: scale(1.1);
          background: $gn-frame-color !important;
          filter: brightness(1);
          top:0px;
          left:0%;
        }
      }
    }
    .Text {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      margin: auto 0rem;
      margin-right: auto;
      //transform: translate(-50%,-50%);
      //transform: translateX(50px);
      //padding: 12px 48px;
      color: $gn-white-color;
      /*
      background: linear-gradient(90deg, 
          rgba($gn-frame-color ,1) 0%, 
          rgba($gn-white-color ,1) 50%,
          rgba($gn-white-color ,1) 50.25%,
          rgba($gn-frame-color ,1) 100%, 
          ) ;
      //background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
      background-position: 0;*/
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
      //animation: shine2 3s infinite linear;
      animation: show 6s 1 linear;
      animation-fill-mode: forwards;
      -webkit-text-size-adjust: none;
      font-weight: 600;
      font-size: 50px;
      text-decoration: none;
      white-space: nowrap;
    }
    @keyframes shine {
      0% {
        //transform :rotate(45deg);
        background-position: 0 0;
      }
      60% {
        //transform :rotate(45deg);
        background-position:-100px -100px;
      }
      100% {
        //transform :rotate(45deg);
        background-position:-200px -200px;
      }
    }
    @keyframes shine2 {
      0% {
        //transform :rotate(45deg);
        background-position: 0px 0px;
      }
      60% {
        //transform :rotate(45deg);
        //background-position:-800px -800px;
      }
      100% {
        //transform :rotate(45deg);
        //background-position:0px 0px;
        //background-position: 0% 100%;
        background-position-x: 319px;
      }
    }
    @keyframes appear {
      0% { opacity: 0; transform: scale(1);}
      49% { opacity: 0; transform: scale(1); background-color: rgba($gn-white-color , 0.0); }
      60% { opacity: 1; transform: scale(1); background-color: rgba($gn-black-color , 0.0);}
      62.5% { background-color: rgba($gn-white-color , 0.0); }
      65% { background-color: rgba($gn-white-color , 0); }
      100% { opacity: 1; transform: scale(1); }
    }
    @keyframes show {
      0% { font-size: 0px;}
      90% { font-size: 0px; }
      100% { font-size: 50px; }
    }
    

  }

  .Moving-title {
    position: absolute;
    z-index : 5;
    top:calc( 50% - 50px);
    left:calc( 50% - 50px);
    height : 100px;
    width: 100px;
    animation:Focus 3s linear 1;
    opacity: 0;
    .Point {
      position: absolute;
      width : 10%;
      height: 10%;
      left:0%; 
      top:0%;
      z-index : 2;
      filter: blur(5px);
      .Star {
        position: absolute;
        left:0%; 
        top:0%;
        width : 100%;
        height: 100%;
        border-radius: 100%;
        background: radial-gradient(circle, rgba($gn-white-color ,0.75) 0%, rgba($gn-frame-color ,0.1) 100%);
        //clip-path: polygon(0 50%, 31% 40%, 19% 18%, 37% 30%, 49% 0, 60% 28%, 80% 18%, 69% 39%, 100% 50%, 71% 57%, 86% 77%, 60% 66%, 50% 100%, 42% 66%, 18% 81%, 31% 58%);
        animation:Roll 0.5s linear infinite;
        box-shadow:
        0 0 30px 20px rgba($gn-frame-color,0.25) ,  /* inner white */
        0 0 50px 40px rgba($gn-white-color,0.5), /* middle magenta */
        0 0 70px 60px rgba($gn-white-color,0.25); /* outer cyan */
      }
      filter: blur(5px);
      //box-shadow: 0px 0px 50px rgba($gn-white-color , 0.9);
      //border-radius: 50%;
      //transition: all 0.25s ease-in-out;
      animation:Movement 3s linear 1;
      &:after {
        content : " ";
        width:5%;
        height:15%;
        display: block;
        background-color: rgba($gn-white-color , 0.25);
      }
      @keyframes Roll {

      }
      //polygon(10% 10%, 39% 36%, 10% 63%, 49% 99%, 89% 63%, 65% 41%, 55% 51%, 68% 63%, 49% 80%, 30% 63%, 89% 10%, 78% 1%, 49% 27%, 20% 0%);
    }
    .R-Wrapper {
      position: absolute;
      left:0px; 
      top:0px;
      bottom:0px;
      right: 0px;;
      z-index : 1;
      filter: blur(5px);
      .Rail {
        position: absolute;
        left:0px; 
        top:0px;
        bottom:0px;
        right: 0px;;
        z-index : 1;
        clip-path : polygon( 10% 0%, 0% 10% ); 
        background-color: rgba($gn-white-color , 0.75);
        //transition: all 0.25s ease-in-out;
        //box-shadow: 0px 0px 50px rgba($gn-black-color , 0.5);;
        //filter: blur(5px);
        animation:Rail 3s linear 1;
      }
    }
    @keyframes Focus {
      0% { transform : scale(1); opacity: 1;display: block; }
      60% { transform : scale(1); opacity: 1;display: block;   }
      //70% { transform : scale(1);   }
      80% { transform : scale(1); opacity: 1;display: block;   }
      99% { transform : scale(1); opacity: 0;   }
      100% { opacity: 0;display: none;   }
    }
    @keyframes Movement {
      0% { left:0%; top:00%;}
      40% { left:90%; top:65%;   }
      62.5% { left:45%; top:90%;   }
      75% { left:0%; top:65%;   }
      90% { left:90%; top:00%;   }
      100% { left:90%; top:00%; }
    }
    @keyframes Rail {
      //0% { clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, -75% 50%, -100% 0%); }
      //75% { clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); }
      //100% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%); }

      0% {   clip-path : polygon(  0% 10%, 10% 0%,  10% 10%,  0% 10%,  0% 10%,    0% 10%,   0% 10%,  0% 10%,  0% 10%,  0% 10%  );  }
      40% {  clip-path : polygon(  0% 10%, 10% 0%, 100% 65%, 80% 65%,  80% 65%,   90% 65%,  80% 65%, 80% 65%,  80% 65%, 80% 65% );    }
      62.5% {  clip-path : polygon(  0% 10%, 10% 0%, 100% 65%, 50% 100%, 50% 100%,  50% 80%,  50% 100%, 50% 80%, 50% 80%,    80% 65%   );     }
      75% {  clip-path : polygon(  0% 10%, 10% 0%, 100% 65%, 50% 100%, 0% 65%,   20% 65%,   20% 60%, 20% 65%, 50% 80%,    80% 65%   );   }
      90% { clip-path : polygon(  0% 10%, 10% 0%, 100% 65%, 50% 100%, 0% 65%,   90%  0%,  100% 10%, 20% 65%, 50% 80%,    80% 65%     ); }
      100% { clip-path : polygon(  0% 10%, 10% 0%, 100% 65%, 50% 100%, 0% 65%,   90%  0%,  100% 10%, 20% 65%, 50% 80%,    80% 65%     ); }
    }
  }

  .Copyright {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    left:0px;
    z-index: 15;
    color : rgba($gn-white-color , 0.5);
    font-size: 12.5px;
    text-align: right;
    padding-right: 1rem;
  }
}


@media screen and (max-width: 750px) {
  .Cover {
    .Msg-Holder {
      .dropping-texts {
        flex-direction: column;
        .Msg {
          margin: auto;
          font-size: 10px;
          display: flex;
          flex-direction: column;
          &:after {
            margin: 0.25rem auto !important;
          }
        }
      }
    }
  }
}